import React from "react";
import { ReactTyped } from "react-typed";

const Hero = () => {
  return (
    <div>
      <div className="max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center">
        {/* <p className='text-purple-900 font-bold p-2 text-2xl'>DE O PROXIMO PASSO</p> */}
        <h1 className="md:text-7xl sm:text-6xl text-4xl font-bold md: py-6">
          Melhore seu negocio
        </h1>
        <div className="flex justify-center items-center ">
          <p className="md:text-4xl sm:text-2xl text-xl font-bold">
            Rapido, Flexivel e feito para
          </p>
          <ReactTyped
            className="pl-2 md:text-4xl sm:text-2xl text-xl font-bold text-purple-900 py-4"
            strings={[
              "Você",
              "Seu Cliente",
              "Sua Empresa",
              "Seus Funcionários",
              "Sua Carreira",
            ]}
            typeSpeed={80}
            backSpeed={90}
            loop
          ></ReactTyped>
        </div>
        <p className="md:text-2xl text-x1 font-bold text-[#1434A4] py-3">
          Implemente a comunicação automática com IA e surpreenda-se
        </p>
        <button className="bg-[#000080] w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-white">
          {" "}
          Contratar Agora{" "}
        </button>
      </div>
    </div>
  );
};

export default Hero;
