import React from "react";

const Email = () => {
  return (
    <div className="w-full py-16">
      <div className="max-w-[1240px] mx-auto grid lg:grid-cols-3">
        <div className="lg:col-span-2">
          <h1 className="pl-2 md:text-4xl sm:text-4xl text-2xl">
            Quer saber mais sobre como um Chatbot pode melhorar sua empresa?
          </h1>
          <p className="pl-2">Deixe seu E-mail e entraremos em contato!</p>
        </div>
        <div className="p-2 my-4 flex justify-center items-center">
          <input
            type="email"
            name="Coloque o E-Mail"
            className="p-3 flex w-full rounded-md text-black h-[60px]"
          />
          <button className="bg-[#000080] w-[200px] rounded-md font-medium ml-4 my-6 py-3 text-white">
            Saber Mais!
          </button>
        </div>
      </div>
    </div>
  );
};

export default Email;
