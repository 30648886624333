import React from "react";
import { Bubble } from "@typebot.io/react";

const ChatBubble = () => {
  return (
    <Bubble
      typebot="sinapse-labs-bot"
      apiHost="https://bot.sinapselabs.com.br"
      theme={{ button: { backgroundColor: "#0042DA" } }}
    />
  );
};

export default ChatBubble;
