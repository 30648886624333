import React from "react";
import Chatbot from "../img/chatbot-icon-blue.png";
import Sistems from "../img/sistems-blue.png";
import Site from "../img/website-blue.png";

const Products = () => {
  return (
    <div className="w-full py-[10rem] px-4 bg-white">
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8">
        <div className="w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300">
          <img
            src={Chatbot}
            alt="ChatBot"
            className="w-20 mx-auto mt-[-3rem]"
          />
          <h2 className="text-2xl font-bold text-center py-8">ChatBot</h2>
          {/* <p className="text-center text-4xl font-bold">Valor</p> */}
          <div className="text-center font-medium">
            <p className="py-2 border-b mx-8 mt-8">
              Implementação em Sites ou Apps
            </p>
            <p className="py-2 border-b mx-8">Inteligencia Artificial</p>
            <p className="py-2 border-b mx-8">Suporte 24 horas</p>
          </div>
          <button className="bg-[#000080] w-[200px] rounded-md font-medium mx-auto my-6 py-3 text-white">
            Quero Esse!
          </button>
        </div>

        <div className="w-full shadow-xl flex flex-col p-4 my-8 md:my-0 rounded-lg hover:scale-105 duration-300 bg-[#f2fdff]">
          <img
            src={Sistems}
            alt="ChatBot"
            className="w-20 mx-auto mt-[-3rem]"
          />
          <h2 className="text-2xl font-bold text-center py-8">Sistemas WEB</h2>
          {/* <p className="text-center text-4xl font-bold">Valor</p> */}
          <div className="text-center font-medium">
            <p className="py-2 border-b mx-8 mt-8">Acesse de qualquer lugar</p>
            <p className="py-2 border-b mx-8">Chatbot e IA</p>
            <p className="py-2 border-b mx-8">Suporte 24 horas</p>
          </div>
          <button className="bg-[#B6D0E2] w-[200px] rounded-md font-medium mx-auto my-6 py-3 text-[#000080]">
            Quero Esse!
          </button>
        </div>

        <div className="w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300">
          <img src={Site} alt="ChatBot" className="w-20 mx-auto mt-[-3rem]" />
          <h2 className="text-2xl font-bold text-center py-8">WebSites</h2>
          {/* <p className="text-center text-4xl font-bold">Valor</p> */}
          <div className="text-center font-medium">
            <p className="py-2 border-b mx-8 mt-8">Customizado</p>
            <p className="py-2 border-b mx-8">Sem Burocracia</p>
            <p className="py-2 border-b mx-8">Suporte 24 horas</p>
          </div>
          <button className="bg-[#000080] w-[200px] rounded-md font-medium mx-auto my-6 py-3 text-white">
            Quero Esse!
          </button>
        </div>
      </div>
    </div>
  );
};

export default Products;
