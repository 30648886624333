import React from "react";
import Chatbot from "../img/chatbot.png";

const ChatSection = () => {
  return (
    <div className="w-full bg-[#B6D0E2] py-16 px-4">
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
        <img src={Chatbot} alt="chatbot" className="w-[500px] mx-auto my-4" />
        <div className="flex flex-col justify-center">
          <p className="text-[#1c0f66] font-bold">
            IMPLEMENTAÇÃO DE CHATBOT COM IA
          </p>
          <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
            Comunicação de Alto Nivel
          </h1>
          <p>
            Transforme a comunicação da sua empresa com um chatbot inteligente e
            personalizado! Nossa solução combina tecnologia avançada e
            personalização para criar assistentes virtuais que atendem às
            necessidades específicas do seu negócio. Desde suporte ao cliente
            até vendas, nossos chatbots otimizam processos, aumentam a
            eficiência e oferecem uma experiência excepcional para seus
            usuários.
          </p>

          <button className="bg-[#8c99e4] w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3 text-white">
            {" "}
            Começar agora!{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatSection;
