import React from "react";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import ChatSection from "./components/ChatSection";
import Email from "./components/Email";
import Products from "./components/Products";
import Footer from "./components/Footer";
import ChatBubble from "./components/ChatBubble";

function App() {
  return (
    <div>
      <Navbar />
      <Hero />
      <ChatSection />
      <Email />
      <Products />
      <Footer />
      <ChatBubble />
    </div>
  );
}

export default App;
